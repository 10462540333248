header {
    width: 100%;
    border-bottom: 2px solid black;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 3vmin;
    align-items: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.header-left {
    width: 40%;
    font-size: 4vmin;
    font-weight: bold;
    letter-spacing: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    div {
        gap: 1em;
    }
}

.header-right {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
}

.header-link {
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    height: 100px;
    transition: background-color 0.2s ease;
}

.header-link {
    /* padding-top: 30px;
    padding-bottom: 0; */
    text-align: center;
    align-items: center;
    position: relative;
    top: 0;
    transition: top 0.2s ease;
    border-radius: 10px;
}

.header-link span {
    position: relative;
    top: 30px;
}

.header-link:hover {
    background-color: rgba(200, 200, 200, 0.205);
    top: -2px;
}

a, a:visited {
    color: inherit;
    text-decoration: none;
}

.social-link {
    font-size: 0.25em;
    align-items: center;
    letter-spacing: normal;
    padding: 5px;
    border-radius: 5px;
}

.social-link:hover {
    background-color: rgba(200, 200, 200, 0.205);
}

.social-link img {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    margin-left: 5px;
    position: relative;
    top: 4px;
}

.social-underline {
    position: relative;
    bottom: 0;
    left: 30px;
    height: 2px;
    background-color: black;
    width: 0px;
    transition: width 0.3s ease;
}

.page-underline {
    position: relative;
    top: 23px;
    height: 2px;
    background-color: black;
    width: 0px;
    transition: width 0.3s ease;
}

@media (max-width: 1000px) {
    header {
        gap: 0.33em;
    }
    .header-left {
        padding-left: 1em;
        display: grid;
        grid-template-columns: 4fr 6fr;
        width: 100%;
        font-size: 4vmin;
    }

    .social-link {
        font-size: 0.4em;
    }

    .header-right {
        width: 100%;
    }

    .header-link {
        margin: 0;
        padding: 0;
        height: auto;
    }

    .header-link p {
        margin: 0;
        padding: 0;
    }

    .header-link span {
        top: 0px;
    }

    .page-underline {
        top: 0px;
    }
}

@media (max-width: 440px) {
    .header-left {
        grid-template-columns: 5fr 5fr;
        text-align: center;
    }

    .header-left p {
        text-wrap: wrap;
        font-size: 5vmin;
        width: 100%;
    }

    .heaer-left div {
        gap: 0;
    }
}

